import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {changeBookQuantity, createBookOrder, fetchBookById} from "../requests/requests";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";
import ApiError from "../entities/ApiError";


export default function BookPage(){
    const navigate = useNavigate();

    const {bookId} = useParams();
    const [serverResponse, setServerResponse] = useState(null);
    let authInfo = ClientAuthInfoManager.get();

    async function loadData(){
        setServerResponse(await fetchBookById(bookId));
    }

    // load Book from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    const handleBookOrderCreation = async event => {
        event.preventDefault();

        let response = await createBookOrder(bookId);

        // handle serverResponse
        if(response[0] === true){
            alert("Created successfully!");
            navigate("/bookOrder/" + response[1].id);
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }

    const handleIncreaseBookQuantity = async event => {
        event.preventDefault();

        let increaseQuantityDelta = document.getElementsByName("increaseQuantityDelta")[0].value;
        if(increaseQuantityDelta === '0'){
            return;
        }

        let response = await changeBookQuantity(bookId, increaseQuantityDelta);

        // handle serverResponse
        if(response[0] === true){
            alert("Increased successfully!");
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }

    const handleDecreaseBookQuantity = async event => {
        event.preventDefault();

        let decreaseQuantityDelta = document.getElementsByName("decreaseQuantityDelta")[0].value;
        if(decreaseQuantityDelta === '0'){
            return;
        }

        let response = await changeBookQuantity(bookId, (-1) * decreaseQuantityDelta);

        // handle serverResponse
        if(response[0] === true){
            alert("Decreased successfully!");
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }


    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
          <ErrorPage apiError={serverResponse[1]} />
        )
    }

    // entity
    let book = serverResponse[1];


    return(
        <div className={"container"}>
            <div className="container mt-5">

                <h1 className="text-center mb-3">Book</h1>

                {/* ID */}
                <div className={"row justify-content-center"}>
                    <div className="col text-end">
                        <b>ID</b>
                    </div>
                    <div className="col">
                        {book.id}
                    </div>
                </div>

                {/* ISBN */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>ISBN</b>
                    </div>
                    <div className="col">
                        {book.isbn}
                    </div>
                </div>

                {/* Title */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Title</b>
                    </div>
                    <div className="col">
                        {book.title}
                    </div>
                </div>

                {/* Authors */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Authors</b>
                    </div>
                    <div className="col">
                        {book.authors}
                    </div>
                </div>

                {/* Number of pages */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Number of pages</b>
                    </div>
                    <div className="col">
                        {book.numberOfPages}
                    </div>
                </div>

                {/* Publishing year */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Publishing year</b>
                    </div>
                    <div className="col">
                        {book.publishingYear}
                    </div>
                </div>

                {/* Quantity */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Quantity</b>
                    </div>
                    <div className="col">
                        {book.quantity}
                    </div>
                </div>
            </div>

            {/* Create Order form */}
            {(authInfo !== null && authInfo.role === "CUSTOMER") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleBookOrderCreation} id="createBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-success" value="Create">Make order!</button>
                        </div>
                    </form>
                </div>
            }

            {/* Change book quantity form */}
            {(authInfo !== null && authInfo.role === "ADMIN") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleIncreaseBookQuantity} id="increaseBookQuantityForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <div className="row mb-3">
                                <label className="col-4 text-end">Increase quantity by</label>
                                <input name="increaseQuantityDelta" type="number" required
                                       min={1} max={100000} step={1} defaultValue={1}
                                       className="form-control col"/>
                                <button type="submit" className="btn btn-success col ml-2">Increase!</button>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleDecreaseBookQuantity} id="decreaseBookQuantityForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <div className="row mb-3">
                                <label className="col-4 text-end">Decrease quantity by</label>
                                <input name="decreaseQuantityDelta" type="number" required
                                       max={book.quantity} min={0} step={1} defaultValue={0}
                                       className="form-control col"/>
                                <button type="submit" className="btn btn-success col ml-2">Decrease!</button>
                            </div>
                        </div>
                    </form>
                </div>
            }
      </div>
    )
}