import {useNavigate} from "react-router-dom";
import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {useEffect} from "react";
import NotAllowedPage from "../pages/auxiliary/NotAllowedPage";
import NoPage from "../pages/auxiliary/NoPage";



/**
 * <p>This is security wrapper which aims to give access to web-page only for allowed users</p>
 * <p>This code checks if a user (that trying to access a protected element) is authenticated,
 * and displays the child when a user is authorized and have required role,
 * or navigate to main page otherwise.</p>
 * @param children
 * @param allowedRoles list of allowed roles
 * @returns {*|null}
 * @constructor
 */
export default function SecurityWrapper({ children, allowedRoles }) {
    let authInfo = ClientAuthInfoManager.get();

    if(authInfo !== null && allowedRoles.includes(authInfo.role)){
        return children;
    }
    else{
        return(
            <NotAllowedPage/>
        );
    }
};