
export default function LoadingPage(){
    return(
        <>
            <div className="container mt-5">
                <p className={"h1 text-center"}>Loading</p>
            </div>

        </>
    );
}