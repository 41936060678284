import {Link, useNavigate} from "react-router-dom";
import {registration} from "../requests/requests";
import ApiError from "../entities/ApiError";
import React from "react";


export default function RegistrationPage(){
    const navigate = useNavigate();

    let handleSubmit = async event => {
        event.preventDefault();

        let registrationDto = {};

        registrationDto.username = document.getElementsByName("username")[0].value;
        registrationDto.password = document.getElementsByName("password")[0].value;
        let passwordRepeat = document.getElementsByName("passwordRepeat")[0].value;
        registrationDto.fullName = document.getElementsByName("fullName")[0].value;
        registrationDto.phoneNumber = document.getElementsByName("phoneNumber")[0].value;

        if(registrationDto.password !== passwordRepeat){
            alert("Password and password repetition must be equal");
            return;
        }

        let serverResponse = await registration(registrationDto);
        if(serverResponse[0] === true){
            console.log("Registered successfully!\n" + JSON.stringify(serverResponse[1]));
            alert("Registered successfully!");
            navigate("/login");
        }
        else{
            console.log(serverResponse[1]);
            alert(ApiError.toString(serverResponse[1]));
        }
    }


    return(
        <div className={"container"}>
            <h1 className={"text-center mt-3 mb-3"}>Registration</h1>

            <form name="form-registration" onSubmit={handleSubmit}>
                <div className={"form-group container"}>

                    {/* Username */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Username</label>
                        <div className="col">
                            <input name="username" type="text" required placeholder="username"
                                   pattern="^\S+$" minLength="5" maxLength="25" className="form-control col"/>
                            <p className="small text-muted">5-25 characters, can't contain space character</p>
                        </div>
                    </div>

                    {/* Password */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Password</label>
                        <div className="col">
                            <input name="password" type="password" required placeholder="password"
                                   pattern={"(.|\\s)*\\S(.|\\s)*"} minLength="8" maxLength="50" className="form-control col"/>
                            <p className="small text-muted">8-50 characters, can't be blank</p>
                        </div>
                    </div>

                    {/* Password repeat */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Repeat password</label>
                        <div className="col">
                            <input name="passwordRepeat" type="password" required placeholder="password"
                                   pattern={"(.|\\s)*\\S(.|\\s)*"} minLength="8" maxLength="50" className="form-control col"/>
                            <p className="small text-muted">Must be equal to entered password</p>
                        </div>
                    </div>

                    {/* Full name */}
                    <div className="row mb-3 mt-2">
                        <label className="col-2 text-end">Full name</label>
                        <div className="col">
                            <input name="fullName" type="text" required
                                   pattern="^(?!\s*$).+" minLength="1" maxLength="80" className="form-control col"/>
                            <p className="small text-muted">1-80 characters, can't be blank</p>
                        </div>
                    </div>

                    {/* Phone number */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Phone number</label>
                        <div className="col">
                            <input name="phoneNumber" type="text" required placeholder="+380---------"
                                   pattern="^\+380\d{2}\d{3}\d{2}\d{2}$" minLength="13" maxLength="13"
                                   className="form-control col"/>
                            <p className="small text-muted">Format: +380xxxxxxxxx</p>
                        </div>
                    </div>


                    {/* Submit */}
                    <div className={"row justify-content-center mt-3"}>
                        <div className="col-2 text-center">
                            <input type="submit" value={"Register!"} className={"btn btn-primary btn-lg form-control mt-3"}/>
                        </div>
                    </div>
                </div>
            </form>

            <div className={"row mt-1 text-center"}>
                <Link to={"/login"}>
                    <button className = {"btn btn-primary btn-lg mt-2"}>Already have an account? Login</button>
                </Link>
            </div>
        </div>
    );
}