/*
    Pages Links necessary to show on navbar:
        Create new PlantingMaterial
        All plantingMaterials

        All Sales
        Create Sale
 */

import {Link, useNavigate} from "react-router-dom";
import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {truncateString} from "./helpers";

export default function NavBar(){
    const navigate = useNavigate();

    let authInfo = ClientAuthInfoManager.get();

    if(authInfo !== null && authInfo.role === "CUSTOMER"){
        return(
            <nav className="navbar navbar-expand bg-light ps-5 pe-5">
                <Link to={"/"} className={"navbar-brand"}>Home</Link>

                <div className="navbar-nav flex-row">
                    <Link to={"/books"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Books</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/bookOrders"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">My book orders</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/user/" + authInfo.id} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary"> Profile ({truncateString(authInfo.login, 20)})
                        </button>
                    </Link>
                </div>

            </nav>
        )
    }
    else if(authInfo !== null && authInfo.role === "ADMIN") {
        return(
            <nav className="navbar navbar-expand bg-light ps-5 pe-5">
                <Link to={"/"} className={"navbar-brand"}>Home</Link>

                <div className="navbar-nav flex-row">
                    <Link to={"/books"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Books</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/book/create"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Create book</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/bookOrders"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Book orders</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/user/" + authInfo.id} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary"> Profile ({truncateString(authInfo.login, 20)})
                        </button>
                    </Link>
                </div>
            </nav>
        )
    }
    else if(authInfo !== null && authInfo.role === "OWNER") {
        return(
            <nav className="navbar navbar-expand bg-light ps-5 pe-5">
                <Link to={"/"} className={"navbar-brand"}>Home</Link>

                <div className="navbar-nav flex-row">
                    <Link to={"/books"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Books</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/users"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Users</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/bookOrders"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Book orders</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/user/" + authInfo.id} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary"> Profile ({truncateString(authInfo.login, 20)})
                        </button>
                    </Link>
                </div>
            </nav>
        )
    }
    else{
        return(
            <nav className="navbar navbar-expand bg-light ps-5 pe-5">
                <Link to={"/"} className={"navbar-brand"}>Home</Link>

                <div className="navbar-nav flex-row">
                    <Link to={"/login"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Login</button>
                    </Link>
                </div>

                <div className="navbar-nav flex-row">
                    <Link to={"/register"} className={"nav-item nav-link p-2"}>
                        <button className="btn btn-primary">Register</button>
                    </Link>
                </div>

            </nav>
        )
    }


    return(
      <nav className="navbar navbar-expand bg-light ps-5 pe-5">
          <div className="navbar-nav flex-row">
              <p>NAV BAR</p>
          </div>
      </nav>
    )






    /*
    return(
        <nav className="navbar navbar-expand bg-light ps-5 pe-5">
            <Link to={"/"} className={"navbar-brand"}>Main</Link>
            <div className="navbar-nav flex-row">

                {(authInfo !== null && authInfo.role === "MANAGER") &&
                    <Link to={"/planting-material/create"} className={"nav-item nav-link p-2"}>
                        <button className = {"btn btn-primary"}>Create PlantingMaterial</button>
                    </Link>
                }

                <Link to={"/planting-material/all"} className={"nav-item nav-link p-2"}>
                    <button className = {"btn btn-primary"}>All PlantingMaterials</button>
                </Link>

                {(authInfo !== null && authInfo.role === "SELLER") &&
                    <>
                        <Link to={"/sale/create"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>Create Sale</button>
                        </Link>
                    </>
                }

                {(authInfo !== null) &&
                    <>
                        <Link to={"/sale/all"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>All Sales</button>
                        </Link>
                        <Link to={"/sale-returning/all"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>All SaleReturnings</button>
                        </Link>

                        <Link to={"/planting-material/arrival/all"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>All arrivals</button>
                        </Link>
                        <Link to={"/planting-material/write-off/all"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>All write-offs</button>
                        </Link>
                    </>
                }

                {(authInfo !== null && authInfo.role === "OWNER") &&
                    <>
                        <Link to={"/user/all"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>All Users</button>
                        </Link>
                    </>
                }

                {(authInfo !== null && (authInfo.role === "OWNER" || authInfo.role === "MANAGER")) &&
                    <>
                        <Link to={"/reports"} className={"nav-item nav-link p-2"}>
                            <button className = {"btn btn-primary"}>Reports</button>
                        </Link>
                    </>
                }

            </div>
        </nav>
    );

     */
}


/*

    Example: https://getbootstrap.com/docs/4.0/components/navbar/#nav
 */