import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {Link, useNavigate} from "react-router-dom";


export default function MainPage(){
    const navigate = useNavigate();
    let authInfo = ClientAuthInfoManager.get();

    const handleLogout = (event) => {
        ClientAuthInfoManager.clear();
        console.log("Logged out");
        navigate("/login");
    }


    return(
        <>
            <h1 className={"text-center mt-3 mb-3"}>Classical Library</h1>
            {(authInfo) ? (
                <>
                    <div className={"container"}>
                        <div className={"row mb-3 text-center"}>
                            <p className={"mb-0 h3 mb-2"}>Welcome, <b>{authInfo.login}</b></p>
                            {/*
                            <p className={"mb-0 h3"}>Your ID = {authInfo.id}</p>
                            <p className={"h3"}>Your role is {authInfo.role}</p>
                            */}

                            <div className={"col"}>
                                <button className = {"btn btn-primary btn-lg"} onClick={handleLogout}>Logout</button>
                            </div>
                        </div>
                    </div>

                </>
            ) : (
                <>
                    <div className={"container"}>
                        <div className={"row mb-3 text-center"}>
                            <p className={"mb-5 h3"}>Welcome!</p>

                            <Link to={"/login"}>
                                <button className = {"btn btn-primary btn-lg mb-3"}>Already have an account? Login</button>
                            </Link>

                            <Link to={"/register"}>
                                <button className = {"btn btn-primary btn-lg"}>Don't have an account? Register</button>
                            </Link>
                        </div>
                    </div>
                </>
                )
            }

        </>
    );
}