import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {
    changeBookQuantity,
    createBookOrder,
    fetchBookById,
    fetchUserById,
    setUserIsAllowedToLogin, setUserRole
} from "../requests/requests";
import ApiError from "../entities/ApiError";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";


export default function UserPage(){
    const navigate = useNavigate();

    const {userId} = useParams();
    const [serverResponse, setServerResponse] = useState(null);
    let authInfo = ClientAuthInfoManager.get();

    async function loadData(){
        setServerResponse(await fetchUserById(userId));
    }

    // load Book from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    const handleSetIsAllowedToLogin = async(userId, isAllowedToLogin) => {
        let response = await setUserIsAllowedToLogin(userId, isAllowedToLogin);

        // handle serverResponse
        if(response[0] === true){
            if(isAllowedToLogin){
                alert("Successfully allowed!");
            }
            else{
                alert("Successfully forbade!");
            }
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }

    const handleSetUserRole = async(user, role) => {
        let response = await setUserRole(userId, role);

        // handle serverResponse
        if(response[0] === true){
            if(role === "ADMIN"){
                alert("Successfully promoted!");
            }
            else if (role === "CUSTOMER"){
                alert("Successfully lowered!");
            }
            else {
                alert("Successfully changed role!");
            }
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }


    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
            <ErrorPage apiError={serverResponse[1]} />
        )
    }

    // entity
    let user = serverResponse[1];


    return(
        <div className={"container"}>
            <div className="container mt-5">

                <h1 className="text-center mb-3">User</h1>


                {/* ID */}
                {(authInfo !== null && authInfo.id !== user.id) &&
                    <div className={"row justify-content-center"}>
                        <div className="col text-end">
                            <b>ID</b>
                        </div>
                        <div className="col">
                            {user.id}
                        </div>
                    </div>
                }

                {/* Username */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Username</b>
                    </div>
                    <div className="col">
                        {user.username}
                    </div>
                </div>

                {/* Full name */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Full name</b>
                    </div>
                    <div className="col">
                        {user.fullName}
                    </div>
                </div>

                {/* Phone number */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Phone number</b>
                    </div>
                    <div className="col">
                        {user.phoneNumber}
                    </div>
                </div>

                {/* Role */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Role</b>
                    </div>
                    <div className="col">
                        {user.role}
                    </div>
                </div>


                {/* is allowed to login */}
                {(authInfo !== null && authInfo.id !== user.id) &&
                    <div className={"row justify-content-center"}>
                        <div className="col text-end">
                            <b>Is allowed to login</b>
                        </div>
                        <div className="col">
                            {user.isAllowedToLogin?'true':'false'}
                        </div>
                    </div>
                }


                {(authInfo !== null && authInfo.role === "OWNER" && user.isAllowedToLogin === true && authInfo.id !== user.id) &&
                    <div className="row justify-content-center mt-3">
                        <form role="form" onSubmit = {(event) =>
                        {event.preventDefault(); handleSetIsAllowedToLogin(user.id, false)}}>
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success" value="Cancel">Forbid to login</button>
                            </div>
                        </form>
                    </div>
                }
                {(authInfo !== null && authInfo.role === "OWNER" && user.isAllowedToLogin === false && authInfo.id !== user.id) &&
                    <div className="row justify-content-center mt-3">
                        <form role="form" onSubmit = {(event) =>
                        {event.preventDefault(); handleSetIsAllowedToLogin(user.id, true)}}>
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success" value="Cancel">Allow to login</button>
                            </div>
                        </form>
                    </div>
                }


                {(authInfo !== null && authInfo.role === "OWNER" && user.role === "CUSTOMER") &&
                    <div className="row justify-content-center mt-3 mb-5">
                        <form role="form" onSubmit = {(event) =>
                        {event.preventDefault(); handleSetUserRole(user.id, "ADMIN")}}>
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success">Promote to ADMIN</button>
                            </div>
                        </form>
                    </div>
                }
                {(authInfo !== null && authInfo.role === "OWNER" && user.role === "ADMIN") &&
                    <div className="row justify-content-center mt-3 mb-5">
                        <form role="form" onSubmit = {(event) =>
                        {event.preventDefault(); handleSetUserRole(user.id, "CUSTOMER")}}>
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success">Lower to CUSTOMER</button>
                            </div>
                        </form>
                    </div>
                }


            </div>

        </div>
    )
}