import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {
    cancelBookLost,
    cancelBookOrder, cancelCompleteBookOrder, cancelSatisfyBookOrder,
    changeBookQuantity, completeBookOrder,
    createBookOrder, deleteBookOrder,
    fetchBookById,
    fetchBookOrderById, satisfyBookOrder, setBookLost
} from "../requests/requests";
import ApiError from "../entities/ApiError";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";


export default function BookOrderPage(){
    const navigate = useNavigate();

    const {bookOrderId} = useParams();
    const [serverResponse, setServerResponse] = useState(null);
    let authInfo = ClientAuthInfoManager.get();

    async function loadData(){
        setServerResponse(await fetchBookOrderById(bookOrderId));
    }

    // load Book from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    const changeBookOrderState = async(changeMethod, successfulText) => {
        let response = await changeMethod(bookOrderId);

        // handle serverResponse
        if(response[0] === true){
            alert(successfulText);
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }


    const handleCancelBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(cancelBookOrder, "Canceled successfully!");
    }

    const handleSatisfyBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(satisfyBookOrder, "Satisfied successfully!");
    }

    const handleCancelSatisfyBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(cancelSatisfyBookOrder, "Canceled successfully!");
    }

    const handleSetBookLostBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(setBookLost, "Set BOOK_LOST successfully!");
    }

    const handleCancelSetBookLostBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(cancelBookLost, "Canceled BOOK_LOST status successfully!");
    }

    const handleCompleteBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(completeBookOrder, "Completed successfully!");
    }

    const handleCancelCompleteBookOrder = async(event) => {
        event.preventDefault();
        await changeBookOrderState(cancelCompleteBookOrder, "Canceled successfully!");
    }

    const handleDeleteBookOrder = async(event) => {
        event.preventDefault();
        let response = await deleteBookOrder(bookOrderId);

        // handle serverResponse
        if(response[0] === true){
            alert("Successfully deleted");
            await loadData();
        }
        else{
            console.log(response[1]);
            alert(ApiError.toString(response[1]));
        }
    }


    //




    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
            <ErrorPage apiError={serverResponse[1]} />
        )
    }

    // entity
    let bookOrder = serverResponse[1];


    return(
        <div className={"container"}>
            <div className="container mt-5">

                <h1 className="text-center mb-3">BookOrder</h1>

                {/* ID */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>ID</b>
                    </div>
                    <div className="col">
                        {bookOrder.id}
                    </div>
                </div>

                {/* Book Title */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Book title</b>
                    </div>
                    <div className="col">
                        <Link to={"/book/" + bookOrder.bookId}>{bookOrder.bookTitle}</Link>

                    </div>
                </div>

                {/* If not customer - see ID of customer*/}
                {(authInfo !== null && authInfo.role !== "CUSTOMER") &&
                    <>
                        {/* Customer username */}
                        <div className="row justify-content-center">
                            <div className="col text-end">
                                <b>Customer username</b>
                            </div>
                            <div className="col">
                                <Link to={"/user/" + bookOrder.customerId}>{bookOrder.customerUsername}</Link>
                            </div>
                        </div>
                    </>

                }

                {/* Status */}
                <div className="row justify-content-center">
                    <div className="col text-end">
                        <b>Status</b>
                    </div>
                    <div className="col">
                        {bookOrder.status}
                    </div>
                </div>
            </div>


            {/* Cancel order */}
            {(authInfo !== null && authInfo.role === "CUSTOMER" && bookOrder.status === "NEW") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleCancelBookOrder} id="cancelBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-success" value="Cancel">Cancel order!</button>
                        </div>
                    </form>
                </div>
            }

            {/* Satisfy */}
            {(authInfo !== null && authInfo.role === "ADMIN" && bookOrder.status === "NEW") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleSatisfyBookOrder} id="satisfyBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-success" value="Cancel">Satisfy order!</button>
                        </div>
                    </form>
                </div>
            }

            {/* Cancel satisfy */}
            {(authInfo !== null && authInfo.role === "ADMIN" && bookOrder.status === "SATISFIED") &&
                <>
                    <div className="row justify-content-center mt-3">
                        <form onSubmit={handleCancelSatisfyBookOrder} id="cancelBookOrderForm" role="form">
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success" value="Cancel">Cancel satisfy!</button>
                            </div>
                        </form>
                    </div>

                    <div className="row justify-content-center mt-3">
                        <form onSubmit={handleSetBookLostBookOrder} id="setBookLostBookOrderForm" role="form">
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success" value="Cancel">Book lost!</button>
                            </div>
                        </form>
                    </div>

                    <div className="row justify-content-center mt-3">
                        <form onSubmit={handleCompleteBookOrder} id="completeBookOrderForm" role="form">
                            <div className="form-group col-xs-5 text-center">
                                <button type="submit" className="btn btn-success" value="Cancel">Complete!</button>
                            </div>
                        </form>
                    </div>
                </>
            }

            {/* Cancel Book_LOST */}
            {(authInfo !== null && authInfo.role === "ADMIN" && bookOrder.status === "BOOK_LOST") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleCancelSetBookLostBookOrder} id="cancelSetBookLostBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-success" value="Cancel">Cancel BOOK_LOST!</button>
                        </div>
                    </form>
                </div>
            }

            {/* Cancel completed */}
            {(authInfo !== null && authInfo.role === "ADMIN" && bookOrder.status === "COMPLETED") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleCancelCompleteBookOrder} id="cancelSetBookLostBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-success" value="Cancel">Cancel COMPLETED!</button>
                        </div>
                    </form>
                </div>
            }


            {/* Delete book order */}
            {/*
            {(authInfo !== null && authInfo.role === "ADMIN") &&
                <div className="row justify-content-center mt-3 mb-5">
                    <form onSubmit={handleDeleteBookOrder} id="deleteBookOrderForm" role="form">
                        <div className="form-group col-xs-5 text-center">
                            <button type="submit" className="btn btn-danger" value="Delete">Delete!</button>
                        </div>
                    </form>
                </div>
            }
            */}


        </div>
    )
}