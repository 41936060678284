export default class ClientAuthInfoManager {
    /*
        clientAuthInfo have these fields:
            id;
            login;
            jwt;
            role;
     */



    static get(){
        return JSON.parse(sessionStorage.getItem("clientAuthInfo"));
    }

    /**
     *
     * @param clientAuthInfo must be JSON object {id, login, password, role}
     */
    static save(clientAuthInfo){
        sessionStorage.setItem("clientAuthInfo", JSON.stringify(clientAuthInfo));
    }

    static clear(){
        sessionStorage.removeItem("clientAuthInfo");
    }
}