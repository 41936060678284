import {useEffect, useState} from "react";
import {fetchAllBooks, fetchAllUsers} from "../requests/requests";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";
import {truncateString} from "../helpers/helpers";
import {Link} from "react-router-dom";


export default function AllUserPage(){
    const [serverResponse, setServerResponse] = useState(null);

    async function loadData(){
        setServerResponse(await fetchAllUsers());
    }


    // load all Books from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // display
    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
            <ErrorPage apiError={serverResponse[1]} />
        )
    }


    let users = serverResponse[1];

    // if no User exist
    if(users === null || users.length === 0){
        return(
            <div className={"container mt-3"}>
                <h1 className={"text-center mb-3"}>Users</h1>
                <p>No user exist</p>
            </div>
        );
    }

    return(
        <div className="container mt-3">
            <h1 className={"text-center mb-3"}>Users</h1>

            <table className="table table-striped">
                <thead>
                <tr>
                    <td><b>ID</b></td>
                    <td><b>Username</b></td>
                    <td><b>Full name</b></td>
                    <td><b>Phone number</b></td>
                    <td><b>Role</b></td>
                    <td><b>Is allowed to login</b></td>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td>
                            {user.id}
                        </td>
                        <td>
                            {truncateString(user.username, 20)}
                        </td>
                        <td>
                            {truncateString(user.fullName, 20)}
                        </td>
                        <td>
                            {truncateString(user.phoneNumber, 20)}
                        </td>
                        <td>
                            {user.role}
                        </td>
                        <td>
                            {user.isAllowedToLogin ? 'true' : 'false'}
                        </td>
                        <td>
                            <Link to={"/user/" + user.id}>Details</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
