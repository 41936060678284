import {Link, useNavigate} from "react-router-dom";
import createBook, {registration} from "../requests/requests";
import ApiError from "../entities/ApiError";
import React from "react";


export default function CreateBookPage(){
    const navigate = useNavigate();

    let handleSubmit = async event => {
        event.preventDefault();

        let createBookDto = {};

        createBookDto.isbn = document.getElementsByName("isbn")[0].value;
        createBookDto.title = document.getElementsByName("title")[0].value;
        createBookDto.authors = document.getElementsByName("authors")[0].value;
        createBookDto.numberOfPages = document.getElementsByName("numberOfPages")[0].value;
        createBookDto.publishingYear = document.getElementsByName("publishingYear")[0].value;


        let serverResponse = await createBook(createBookDto);
        if(serverResponse[0] === true){
            console.log("Created successfully!\n Book with ID = " + serverResponse[1].id);
            alert("Created successfully!");
            navigate("/book/" + serverResponse[1].id);
        }
        else{
            console.log(serverResponse[1]);
            alert(ApiError.toString(serverResponse[1]));
        }
    }


    return(
        <div className={"container"}>
            <h1 className={"text-center mt-3 mb-3"}>Create book</h1>

            <form name="form-registration" onSubmit={handleSubmit}>
                <div className={"form-group container"}>

                    {/* ISBN */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">ISBN</label>
                        <div className="col">
                            <input name="isbn" type="text" required placeholder="isbn"
                                   pattern="^(?:ISBN(?:-1[03])?:?\ )?(?=[0-9X]{10}$|(?=(?:[0-9]+[-\ ]){3})[-\ 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[-\ ]){4})[-\ 0-9]{17}$)(?:97[89][-\ ]?)?[0-9]{1,5}[-\ ]?[0-9]+[-\ ]?[0-9]+[-\ ]?[0-9X]$"
                                   maxLength="17"
                                   className="form-control col"/>
                            <p className="small text-muted">Correct ISBN format (without "ISBN" word), up to 17 characters</p>
                        </div>
                    </div>

                    {/* Title */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Title</label>
                        <div className="col">
                            <input name="title" type="text" required placeholder="Title"
                                   pattern="^(?!\s*$).+" minLength="1" maxLength="70" className="form-control col"/>
                            <p className="small text-muted">1-70 characters, can't be blank</p>
                        </div>
                    </div>

                    {/* Authors */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Authors</label>
                        <div className="col">
                            <input name="authors" type="text" required placeholder="Authors"
                                   pattern="^(?!\s*$).+" minLength="1" maxLength="100" className="form-control col"/>
                            <p className="small text-muted">1-100 characters, can't be blank</p>
                        </div>
                    </div>

                    {/* Number of pages */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Number of pages</label>
                        <input name="numberOfPages" type="number" required
                               min="1" max="10000" step="1" defaultValue={1} className="form-control col"/>
                    </div>

                    {/* Publishing year */}
                    <div className="row mb-3">
                        <label className="col-2 text-end">Publishing year</label>
                        <input name="publishingYear" type="number" required
                               min="0" max={new Date().getFullYear() + 1} step={1}
                               defaultValue={new Date().getFullYear()} className="form-control col"/>
                    </div>


                    {/* Submit */}
                    <div className={"row justify-content-center mt-3"}>
                        <div className="col-2 text-center">
                            <input type="submit" value={"Create!"} className={"btn btn-primary btn-lg form-control mt-3"}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}