import React, {useEffect, useState} from "react";
import {fetchAllBooks} from "../requests/requests";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";
import {Link} from "react-router-dom";
import {truncateString} from "../helpers/helpers";
import ClientAuthInfoManager from "../ClientAuthInfoManager";


export default function AllBookPage(){
    const [serverResponse, setServerResponse] = useState(null);
    let authInfo = ClientAuthInfoManager.get();

    async function loadData(){
        setServerResponse(await fetchAllBooks());
    }


    // load all Books from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // display
    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
            <ErrorPage apiError={serverResponse[1]} />
        )
    }


    let books = serverResponse[1];

    // if no Book exist
    if(books === null || books.length === 0){
        return(
            <div className={"container mt-3"}>
                <h1 className={"text-center mb-3"}>Books</h1>
                <p>No book exist</p>

                {(authInfo !== null && authInfo.role === "ADMIN") &&
                    <div className={"row mt-1 mb-5 text-center"}>
                        <Link to={"/book/create"}>
                            <button className = {"btn btn-primary btn-lg mt-5"}>Create new book</button>
                        </Link>
                    </div>
                }
            </div>
        );
    }

    return(
        <div className="container mt-3">
            <h1 className={"text-center mb-3"}>Books</h1>

            <table className="table table-striped">
                <thead>
                <tr>
                    <td><b>ID</b></td>
                    <td><b>ISBN</b></td>
                    <td><b>Title</b></td>
                    <td><b>Authors</b></td>
                    <td><b>Number of pages</b></td>
                    <td><b>Publishing year</b></td>
                    <td><b>Quantity</b></td>
                </tr>
                </thead>
                <tbody>
                {books.map((book) => (
                    <tr key={book.id}>
                        <td>
                            {book.id}
                        </td>
                        <td>
                            {book.isbn}
                        </td>
                        <td>
                            {truncateString(book.title, 20)}
                        </td>
                        <td>
                            {truncateString(book.authors, 20)}
                        </td>
                        <td>
                            {book.numberOfPages}
                        </td>
                        <td>
                           {book.publishingYear}
                        </td>
                        <td>
                            {book.quantity}
                        </td>
                        <td>
                            <Link to={"/book/" + book.id}>Details</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {(authInfo !== null && authInfo.role === "ADMIN") &&
                <div className={"row mt-1 mb-5 text-center"}>
                    <Link to={"/book/create"}>
                        <button className = {"btn btn-primary btn-lg mt-5"}>Create new book</button>
                    </Link>
                </div>
            }

        </div>
    );


}