import {performDeleteRequest, performGetRequest, performPatchRequest, performPostRequest} from "./RequestsTemplates";

///////////////////////////////// Authentication /////////////////////////////////
/**
 * Sends request about registering new user
 * @param registrationDto is object with fields from RegistrationDto:
 * login, password, name, surname, patronymic, phoneNumber, role
 * @return [true, User] if registration succeed, [false, ApiError] otherwise
 */
export function registration(registrationDto){
    return performPostRequest("/register", registrationDto);
}

/**
 * Sends request for authentication, and retunrs JWT
 * @return {Promise<boolean[]|(boolean|ApiError)[]>}
 * [true, jwt] if authentication succeed, [false, ApiError] otherwise
 */
export function authenticate(username, password){
    return performPostRequest("/authenticate", {
        username: username,
        password: password
    });
}

export function getCurrentUser() {
    return performGetRequest("/currentUser", "");
}


///////////////////////////////// Book /////////////////////////////////
export function fetchBookById(id){
    return performGetRequest("/book/"+id, new URLSearchParams({}));
}

export function fetchAllBooks(){
    return performGetRequest("/books/all", new URLSearchParams({}));
}

export default function createBook(createBookDto){
    return performPostRequest("/book/create", createBookDto);
}

export function changeBookQuantity(bookId, quantityDelta){
    return performPatchRequest("/book/changeQuantity",
        {bookId: bookId, quantityDelta: quantityDelta});
}








///////////////////////////////// BookOrder /////////////////////////////////
export function fetchBookOrderById(id){
    return performGetRequest("/bookOrder/"+id, new URLSearchParams({}));
}

export function fetchAllBookOrders(){
    return performGetRequest("/bookOrders/all", new URLSearchParams({}));
}

export function fetchAllBookOrdersForCustomer(customerId){
    return performGetRequest("/bookOrders",
        new URLSearchParams({customerId : customerId}));
}

export function createBookOrder(bookId){
    return performPostRequest("/bookOrder/create", {bookId: bookId});
}


export function cancelBookOrder(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/cancel", {});
}

export function satisfyBookOrder(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/satisfy", {});
}

export function cancelSatisfyBookOrder(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/cancelSatisfy", {});
}

export function setBookLost(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/setBookLost", {});
}

export function cancelBookLost(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/cancelBookLost", {});
}

export function completeBookOrder(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/complete", {});
}

export function cancelCompleteBookOrder(bookOrderId){
    return performPatchRequest("/bookOrder/" + bookOrderId + "/cancelComplete", {});
}







///////////////////////////////// User /////////////////////////////////
export function fetchUserById(id){
    return performGetRequest("/user/"+id, new URLSearchParams({}));
}

export function fetchAllUsers(){
    return performGetRequest("/users/all", new URLSearchParams({}));
}

export function setUserIsAllowedToLogin(userId, isAllowedToLogin){
    return performPatchRequest("/user/setIsAllowedToLogin", {
        userId : userId,
        isAllowedToLogin : isAllowedToLogin
    });
}

export function setUserRole(userId, role){
    return performPatchRequest("/user/setRole", {
        userId : userId,
        role : role
    });
}




/////////////////////////////// DELETE requests
export function deleteBook(bookId){
    return performDeleteRequest("/book/delete?id="+bookId, {});
}

export function deleteBookOrder(bookOrderId){
    return performDeleteRequest("/bookOrder/delete?id=" + bookOrderId, {});
}

export function deleteUser(userId){
    return performDeleteRequest("/user/delete?id=" + userId, {});
}
