import ApiError from "../entities/ApiError";
import ClientAuthInfoManager from "../ClientAuthInfoManager";

const backendName = "https://information-networks-lab2-backend.onrender.com";

/*
    Preprocessed servers response is array [bool, Object],
    where first (bool) element denotes whether response has "200 OK"-status or not;
    second element is response body object (or null if server doesn't provide a body) if bool===true, and ApiError object otherwise
 */

/**
 * Creates Authorization header for request
 * @return "bearer token" if AuthInfo is present,
 * empty string otherwise
 */
export function createAuthorizationHeader(){
    let header = "";

    let authInfo = ClientAuthInfoManager.get();
    if(authInfo !== null){
        header = "bearer " + authInfo.jwt;
    }

    return header;
}


/**
 * <p>Template method to make GET request to server.</p>
 * It became possible, because all GET requests are similar.
 * Also, pre-processing of servers response is unique for all of them
 * @param url should start from '/'
 * @param params should be URLSearchParams object
 * @returns {Promise<boolean[]|(boolean|ApiError)[]>} preprocessed servers response
 */
export async function performGetRequest(url, params){
    try{
        let response = await fetch(backendName + url + '?' + params, {
            method: "GET",
            headers: {
                'Authorization': createAuthorizationHeader()
            }
        });

        return [response.ok, parseResponse(response.ok, await response.text())];
    } catch(err){
        console.log(err);
        return [false, ApiError.createUnknownError()];
    }
}

/**
 * <p>Template method to make POST request to server.</p>
 * It became possible, because all POST requests are similar.
 * Also, pre-processing of servers response is unique for all of them
 * @param url should start from '/'
 * @param body object that should be a body of request
 * @returns {Promise<boolean[]|(boolean|ApiError)[]>} preprocessed servers response
 */
export async function performPostRequest(url, body){
    try{
        let response = await fetch(backendName + url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': createAuthorizationHeader()
            },
            body: JSON.stringify(body)
        });

        return [response.ok, parseResponse(response.ok, await response.text())];
    } catch(err){
        console.log(err);
        return [false, ApiError.createUnknownError()];
    }
}


/**
 * <p>Template method to make PATCH request to server.</p>
 * It became possible, because all PATCH requests are similar.
 * Also, pre-processing of servers response is unique for all of them
 * @param url should start from '/'
 * @param body object that should be a body of request
 * @returns {Promise<boolean[]|(boolean|ApiError)[]>} preprocessed servers response
 */
export async function performPatchRequest(url, body){
    try{
        let response = await fetch(backendName + url, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': createAuthorizationHeader()
            },
            body: JSON.stringify(body)
        });

        return [response.ok, parseResponse(response.ok, await response.text())];
    } catch(err){
        console.log(err);
        return [false, ApiError.createUnknownError()];
    }
}


export async function performDeleteRequest(url, params){
    try{
        let response = await fetch(backendName + url + '?' + params, {
            method: "DELETE",
            headers: {
                'Authorization': createAuthorizationHeader()
            }
        });

        return [response.ok, parseResponse(response.ok, await response.text())];
    } catch(err){
        console.log(err);
        return [false, ApiError.createUnknownError()];
    }
}




/**
 * Parses response.text() as a JSON object; It can be either some entity object, eiter ApiError object;
 * <p></p>
 * In case response.text() is not a JSON object: if response status is "OK" returns null, otherwise returns unknown-ApiError.
 * Note, that response.text() will be ignored
 * @param responseOk is response.ok
 * @param responseText is response.text
 * @returns response.text() as JSON object according to described rules
 */
export function parseResponse(responseOk, responseText){
    let obj = null;
    try{
        obj = JSON.parse(responseText);
        if(responseOk === false){
            if(!ApiError.isApiError(obj)){ // in case, when error message is not in format of ApiError
                obj = ApiError.createUnknownError();
            }
        }
    } catch(err){ // if response.text() is not a JSON
        if(!responseOk){
            obj = ApiError.createUnknownError();
        }
        else{
            obj = null; // Everything is OK; Response is just a "200 OK"-response without JSON-body
        }
    }

    return obj;
}