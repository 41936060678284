import {Link} from "react-router-dom";


export default function NotAllowedPage(){
    return(
        <>
            <div className={"container"}>
                <h1 className={"text-center mt-3 mb-3"}>You are not allowed here!</h1>

                <div className={"row mb-3 text-center"}>
                    <Link to={"/"}>
                        <button className = {"btn btn-primary btn-lg mb-3"}>Go to Main page</button>
                    </Link>
                </div>
            </div>
        </>
    )
}