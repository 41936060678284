import ApiError from "../../entities/ApiError";
import ClientAuthInfoManager from "../../ClientAuthInfoManager";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


/// This Component is called anytime when we need to display error (except cases where alert() is used)
export default function ErrorPage({apiError}){
    const navigate = useNavigate();


    /*
        For cases when credentials become invalid
        (user is already authenticated (credentials are stored on frontend),
        but when make request to backend, it says that JWT is incorrect
        (maybe because of expiration, or isAllowedToLogin changed, etc))
     */

    // if user no longer authenticated (frontend-stored credentials are not correct anymore)
    // if frontend has no credentials stored, then this function is unreachable
    useEffect(() => {
        if(apiError.status === "UNAUTHORIZED"){
            ClientAuthInfoManager.clear(); // delete saved auth info, because it is outdated now
            console.log("deleted ClientAuthInfo");
            navigate("/login")

            alert("Your session is not valid anymore")
        }
    }, []);



    return(
        <div className={"container mt-5"}>
            <h1 className={"text-center"}>ERROR</h1>
            <p>{ApiError.toString(apiError)}</p>
        </div>
    );
}