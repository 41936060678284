import logo from './logo.svg';
import './App.css';
import NavBar from "./helpers/NavBar";
import SecurityWrapper from "./helpers/SecurityWrapper";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import MainPage from "./pages/MainPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NoPage from "./pages/auxiliary/NoPage";
import BookPage from "./pages/BookPage";
import BookOrderPage from "./pages/BookOrderPage";
import UserPage from "./pages/UserPage";
import CreateBookPage from "./pages/CreateBookPage";
import AllBookPage from "./pages/AllBookPage";
import AllBookOrderPage from "./pages/AllBookOrderPage";
import AllUserPage from "./pages/AllUserPage";

function App() {
  return(
    <div>
      <BrowserRouter>
        <Routes>
            <Route index element={
            <>
                <NavBar />
                <MainPage />
            </>
            } />

            <Route path="/register" element={
                <RegistrationPage />
            } />

            <Route path="/login" element={
                <LoginPage />
            } />


            <Route path="/book/:bookId" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN", "CUSTOMER"]}>
                        <BookPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/bookOrder/:bookOrderId" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN", "CUSTOMER"]}>
                        <BookOrderPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/user/:userId" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN", "CUSTOMER"]}>
                        <UserPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/book/create" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["ADMIN"]}>
                        <CreateBookPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/books" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN", "CUSTOMER"]}>
                        <AllBookPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/bookOrders" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN", "CUSTOMER"]}>
                        <AllBookOrderPage />
                    </SecurityWrapper>
                </>
            } />

            <Route path="/users" element={
                <>
                    <NavBar />
                    <SecurityWrapper allowedRoles={["OWNER", "ADMIN"]}>
                        <AllUserPage/>
                    </SecurityWrapper>
                </>
            } />
          <Route path="*" element={<><NavBar /><NoPage /> </>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;