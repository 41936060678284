import {useEffect, useState} from "react";
import {fetchAllBookOrders, fetchAllBookOrdersForCustomer, fetchAllBooks} from "../requests/requests";
import LoadingPage from "./auxiliary/LoadingPage";
import ErrorPage from "./auxiliary/ErrorPage";
import {Link} from "react-router-dom";
import {truncateString} from "../helpers/helpers";
import ClientAuthInfoManager from "../ClientAuthInfoManager";


export default function AllBookOrderPage(){
    const [serverResponse, setServerResponse] = useState(null);
    let authInfo = ClientAuthInfoManager.get();

    async function loadData(){
        if(authInfo !== null && (authInfo.role === "OWNER" || authInfo.role === "ADMIN")){
            setServerResponse(await fetchAllBookOrders());
        }
        else if(authInfo !== null && (authInfo.role === "CUSTOMER")){
            setServerResponse(await fetchAllBookOrdersForCustomer(authInfo.id));
        }
    }


    // load all Book orders from server
    useEffect(() => {
        loadData();
    }, []); // in [] put arguments


    // if data not loaded yet
    if(serverResponse === null){
        return <LoadingPage/>
    }

    // display
    // if Server returned error, or some error occurred
    if(serverResponse[0] === false){
        return(
            <ErrorPage apiError={serverResponse[1]} />
        )
    }


    let bookOrders = serverResponse[1];

    // if no BookOrder exist
    if(bookOrders === null || bookOrders.length === 0){
        return(
            <div className={"container mt-3"}>
                {(authInfo !== null && (authInfo.role === "OWNER" || authInfo.role === "ADMIN")) &&
                    <h1 className={"text-center mb-3"}>Book orders</h1>
                }
                {(authInfo !== null && authInfo.role === "CUSTOMER") &&
                    <h1 className={"text-center mb-3"}>Book orders for customer</h1>
                }

                <p>No book order exist</p>
            </div>
        );
    }

    return(
        <div className="container mt-3">
            {(authInfo !== null && (authInfo.role === "OWNER" || authInfo.role === "ADMIN")) &&
                <h1 className={"text-center mb-3"}>Book orders</h1>
            }
            {(authInfo !== null && authInfo.role === "CUSTOMER") &&
                <h1 className={"text-center mb-3"}>Books orders customer</h1>
            }


            <table className="table table-striped">
                <thead>
                <tr>
                    <td><b>ID</b></td>
                    <td><b>Book ID</b></td>
                    <td><b>Book title</b></td>
                    <td><b>Customer ID</b></td>
                    <td><b>Customer username</b></td>
                    <td><b>Status</b></td>
                </tr>
                </thead>
                <tbody>
                {bookOrders.map((bookOrder) => (
                    <tr key={bookOrder.id}>
                        <td>
                            {bookOrder.id}
                        </td>
                        <td>
                            {bookOrder.bookId}
                        </td>
                        <td>
                            {truncateString(bookOrder.bookTitle, 20)}
                        </td>
                        <td>
                            {bookOrder.customerId}
                        </td>
                        <td>
                            {truncateString(bookOrder.customerUsername, 20)}
                        </td>
                        <td>
                            {bookOrder.status}
                        </td>
                        <td>
                            <Link to={"/bookOrder/" + bookOrder.id}>Details</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );


}