import {Link} from "react-router-dom";


export default function NoPage(){
    return(
        <>
            <div className={"container"}>
                <h1 className={"text-center mt-5 mb-5"}>Page not found!</h1>
            </div>
        </>
    );
}