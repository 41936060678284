import ClientAuthInfoManager from "../ClientAuthInfoManager";
import {authenticate, authentication, getCurrentUser, registration} from "../requests/requests";
import ApiError from "../entities/ApiError";
import {Link, useNavigate} from "react-router-dom";
import React from "react";


export default function LoginPage(){
    const navigate = useNavigate();


    let handleSubmit = async event => {
        event.preventDefault();

        let login = document.getElementsByName("username")[0].value;
        let password = document.getElementsByName("password")[0].value;



        // get JWT
        // set JWT as object, to perform request tot get all data about current user
        // set full data about user


        let authInfo = {
            id: null,
            login: null,
            jwt: null,
            role: null
        }


        // get JWT
        let serverResponseJwt = await authenticate(login, password);
        if(serverResponseJwt[0] === true){
            authInfo.jwt = serverResponseJwt[1].jwt;
            ClientAuthInfoManager.save(authInfo); // set only JWT to do request for UserInfo

            // get rest of info, using JWT
            let serverResponseUserInfo = await getCurrentUser();
            if(serverResponseUserInfo[0] === true){
                authInfo.id = serverResponseUserInfo[1].id;
                authInfo.login = serverResponseUserInfo[1].username;
                authInfo.role = serverResponseUserInfo[1].role;
                ClientAuthInfoManager.save(authInfo);

                console.log("Logged in: " + JSON.stringify(authInfo));
                navigate("/");
            }
            else{
                ClientAuthInfoManager.clear(); // delete saved jwt
                console.log(serverResponseJwt[1]);
                alert(ApiError.toString(serverResponseJwt[1]));
            }
        }
        else{ // authentication error (incorrect password, etc.)
            console.log(serverResponseJwt[1]);
            alert(ApiError.toString(serverResponseJwt[1]));
        }
    }


    return(
        <div className={"container"}>
            <h1 className={"text-center mt-3 mb-3"}>Authorization</h1>

            <form name={"form-authorization"} onSubmit={handleSubmit}>

                {/* Username */}
                <div className={"row mb-3"}>
                    <label className="col-2 text-end">Username</label>
                    <input name="username" type="text" required={true} pattern={"^\\S+$"}
                           minLength={5} maxLength={25} className={"form-control col"}/>
                </div>

                {/* Password */}
                <div className={"row mb-3"}>
                    <label className="col-2 text-end">Password</label>
                    <input name="password" type="password" required={true}
                           minLength={8} maxLength={50} className={"form-control col"}/>
                </div>

                {/* Submit */}
                <div className={"row justify-content-center mt-3"}>
                    <div className="col-2 text-center">
                        <input type="submit" value={"Login!"} className={"btn btn-primary btn-lg form-control mt-3"}/>
                    </div>
                </div>
            </form>


            <div className={"row mt-1 text-center"}>
                <Link to={"/register"}>
                    <button className = {"btn btn-primary btn-lg mt-2"}>Don't have an account? Register</button>
                </Link>
            </div>

        </div>
    );
}

