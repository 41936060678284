export default class ApiError{
    status;
    errorMessages;

    constructor(status, errorMessages) {
        this.status = status;
        this.errorMessages = errorMessages;
    }

    static createUnknownError(){
        return new ApiError("UNKNOWN_ERROR", [{text: "Unknown error occurred"}]);
    }

    static toString(apiError){
        // collect errorMessageTexts
        let errorMessageTexts = "";
        apiError.errorMessages.forEach((errorMessage) =>
            errorMessageTexts = errorMessageTexts + errorMessage.text + "\n" );
        return "ERROR:\n" + apiError.status + "\n" + errorMessageTexts;
    }

    static isApiError(obj){
        // check status field
        if(obj.status === undefined || obj.status === null){
            return false;
        }

        // check errorMessages field
        if(obj.errorMessages === undefined || obj.errorMessages === null ||
            !Array.isArray(obj.errorMessages)){
            return false;
        }
        let isAllMessagesHaveText = obj.errorMessages.every(function(message, index, array){
            return message.text !== undefined && message.text !== null;
        })
        if(!isAllMessagesHaveText){
            return false;
        }

        return true;
    }
}